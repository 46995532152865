import React, { Suspense } from 'react';
import BlogVd from '../assets/blog.mp4';
import futurePlans from '../assets/futurePlans.png';
import joinTheJourney from '../assets/joinTheJourney.jpg';
import movementOrBeTold from '../assets/movementOrBeTold.jpeg';
import ourMission from '../assets/ourMission.png';
import LazyComponent from './LazyComponent';
import { Fade, Slide } from 'react-awesome-reveal';
import str4 from '../assets/stripe1.png';
import str5 from '../assets/strip2.png';
function forBlog() {

    return (
        <>
            <LazyComponent src={BlogVd} />
            <section className="lg:min-h-[90vh] bg-no-repeat min-h-[50vh] bg-cover bg-no-repeat bg-white flex flex-col justify-evenly items-center p-3" style={{ backgroundImage: `url(${str4})`, backgroundPositionY: '50%' }}>
                <div className='text-center p-2'>
                    <Fade delay={300} triggerOnce duration={100} cascade className='text-3xl lg:text-5xl text-black'>
                        Future
                    </Fade>
                    <Fade delay={1000} triggerOnce duration={400} cascade className='ms-2 text-3xl lg:text-5xl font-bold text-[#E20102] '>
                        Plans
                    </Fade>
                </div>
                <div className='bg-white md:w-[85%] w-[95%] md:p-5 p-3 flex flex-col rounded-xl'>
                    <div className='md:text-4xl text-2xl font-extrabold'>
                        Shesha's Plan for E-hailing in South Africa:
                    </div>
                    <div className='lg:flex justify-between'>
                        <div className='flex flex-col gap-3 md:w-[60%]'>
                            <h1 className='md:text-4xl text-2xl font-extrabold text-[#E20102] py-5'>A Brighter Future</h1>
                            <p className='md:w-[65%]'>At Shesha, we're passionate about revolutionizing the e-hailing industry in South
                                Africa. Our goal is to create a safer, more sustainable, and equitable transportation
                                ecosystem for drivers and riders alike.
                            </p>
                            <h5 className='font-bold'>Our Envisioned Future</h5>
                            <div className='md:w-[75%]'>
                                <h6>We envision an e-hailing industry where:</h6>
                                <ul className='list-disc px-8'>
                                    <li className=''>Drivers operate freely, without intimidation or fear.</li>
                                    <li className=''>Both drivers and riders are safe and protected.</li>
                                    <li className=''>Drivers have access to essential benefits, including funeral cover, life insurance,
                                        and medical insurance. etc.</li>
                                    <li className=''>Prices are affordable for the riders.</li>
                                    <li className=''>Quality service and professional service is a standard.</li>
                                    <li className=''>Drivers are compensated fairly.</li>
                                    <li className=''>E-hailing services are integrated into the broader transportation network.</li>
                                    <li className=''>Drivers are empowered and developed.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='lg:h-[46vh] md:w-[40%] h-[30vh] md:p-0 p-2'>
                            <img className='h-[100%] w-[100%] object-contain' src={futurePlans} />
                        </div>
                    </div>

                </div>
            </section>
            <section className="lg:min-h-[90vh] bg-no-repeat min-h-[50vh] bg-cover bg-no-repeat bg-white flex flex-col justify-evenly items-center p-3" style={{ backgroundImage: `url(${str5})`, backgroundPositionY: '48%' }}>
                <div className='text-center p-2'>
                    <Fade delay={300} triggerOnce duration={100} cascade className='text-3xl lg:text-5xl text-black'>
                        Moving
                    </Fade>
                    <Fade delay={1000} triggerOnce duration={400} cascade className='ms-2 text-3xl lg:text-5xl font-bold text-[#E20102] '>
                        Forward
                    </Fade>
                </div>
                <div className='bg-white md:w-[85%] w-[95%] md:p-5 p-3 flex flex-col rounded-xl'>
                    <div className='lg:flex justify-between flex-row-reverse'>
                        <div className='flex flex-col gap-3 md:w-[60%] md:px-10'>
                            <h1 className='md:text-4xl text-2xl font-extrabold text-[#E20102] py-5'>Our Mission</h1>
                            <p className='md:w-[65%]'>Our mission is to deliver value to all our stakeholders, and to achieve our mission
Shesha is committed to:
                            </p>
                            {/* <h5 className='font-bold'>Our Envisioned Future</h5> */}
                            <div className='md:w-[75%] py-5'>
                                {/* <h6>We envision an e-hailing industry where:</h6> */}
                                <ul className='list-disc px-8'>
                                    <li className=''>Implementing innovative safety features and technologies.</li>
                                    <li className=''>Collaborating with stakeholders to establish industry-wide safety standards.</li>
                                    <li className=''>Providing drivers with access to affordable insurance and medical aid options.</li>
                                    <li className=''>Advocating for policy changes that support the growth of e-hailing.</li>
                                    <li className=''>Fostering a collaborative and inclusive industry environment.</li>
                                    <li className=''>Driving innovation and sustainability in transportation.</li>
                                    <li className=''>Supporting driver entrepreneurship and economic growth.</li>
                                    <li className=''>Providing resources and training for professional development.</li>
                                    <li className=''>Ensuring drivers' rights and interests are represented.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='lg:h-[46vh] md:w-[40%] h-[30vh] md:p-0 p-2'>
                            <img className='h-[100%] w-[100%] object-contain' src={ourMission} />
                        </div>
                    </div>

                </div>
            </section>
            <div className="lg:min-h-[90vh] min-h-[50vh] bg-no-repeat bg-cover bg-white flex flex-col justify-evenly items-center p-3"
                 style={{
                    backgroundImage: `url(${joinTheJourney})`,
                    backgroundSize: 'fill', // Ensures the image covers the container without distortion
                    backgroundPosition: 'center', // Centers the image within the container
                  }}>
                <div className=''>
                    <div className='text-center p-2'>
                        <Fade delay={300} triggerOnce duration={100} cascade className='text-3xl lg:text-5xl text-white'>
                            Join the
                        </Fade>
                        <Fade delay={1000} triggerOnce duration={400} cascade className='ms-2 text-3xl lg:text-5xl font-bold text-[#E20102] '>
                            Journey
                        </Fade>
                    </div>
                </div>
                <div className='lg:w-[43%]  bg-[#E20102] opacity-80 text-center font-semibold rounded-[50px] p-2'>
                    <p className='text-white md:text-2xl md:px-10 md:p-6 p-2 '>
                        As we embark on this journey, we invite you to share your
                        thoughts and ideas. Together, let's shape the future of e-hailing
                        in South Africa.
                    </p>
                    <p className='text-white md:text-2xl md:px-10 md:p-6 p-2'>
                        Stay tuned for upcoming blog posts, where we'll delve deeper
                        into our vision and plans.
                    </p>
                </div>
            </div>
            <div className=" bg-no-repeat bg-contain bg-white"
                style={{
                    backgroundImage: `url(${str4})`,
                    backgroundPositionY: '140%',
                    backgroundSize: '100%'
                }}>

                <div className='w-full pt-[10%] relative'>
                    
                    <img className='w-full xl:h-[900px] lg:h-[600px] md:h-[400px] h-[250px] md:object-cover' src={movementOrBeTold} alt='part of the movement or be told' />
                    <div className='text-end absolute top-1 right-1 z-10 transform translate-y-[120%] sm:translate-y-[150%] md:translate-y-[200%] lg:translate-y-[250%] xl:translate-y-[280%] translate-x-0 md:translate-x-[-10%] p-4'>
                        <Fade
                            delay={300}
                            triggerOnce
                            duration={100}
                            cascade
                            className='text-lg md:text-2xl lg:text-5xl text-black font-bold'>
                            Be part of the
                        </Fade><br/>
                        <Fade
                            delay={1000}
                            triggerOnce
                            duration={400}
                            cascade
                            className='ms-2 text-xl md:text-3xl lg:text-5xl font-extrabold text-[#E20102]'>
                            movement
                        </Fade>
                        <Fade
                            delay={300}
                            triggerOnce
                            duration={100}
                            cascade
                            className='text-lg md:text-2xl lg:text-5xl text-black font-bold'> or be told.
                        </Fade>
                    </div>
                </div>

            </div>
        </>

    );
}

export default forBlog;