import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import HomeTop from '../assets/faq.jpg';
import str3 from '../assets/str3.png';
import str2 from '../assets/strip2.png';
import str1 from '../assets/stripe1.png';


import Accordion from './Accordion';

function Faq() {
    const data = [
        {
            q: 'HOW DO I SIGN UP AS A SHESHA CUSTOMER ?',
            ans: `Download the Shesha app from your respective app store, register with your identity number, and once verified, you're ready to ride.`
        },
        {
            q: 'HOW DO I ADD FUNDS TO MY SHESHA WALLET ?',
            ans: `You can top up your wallet using a debit or credit card within the app or deposit money at any bank ATM using your unique wallet ID as a reference.`
        },
        {
            q: 'CAN I REQUEST A SPECIFIC TYPE OF VEHICLE?',
            ans: 'Yes, Shesha offers various vehicle categories to choose from, ranging from 2-seater compact cars to 7- or 9-seater family vehicles.'
        },
        {
            q: 'WHAT HAPPENS IF I FORGET AN ITEM IN A SHESHA VEHICLE?',
            ans: "Please contact our customer service immediately, and we'll assist you in coordinating with the driver to retrieve your item."
        }
    ];

    const driver = [
        {
            q: 'HOW DO I BECOME A SHESHA DRIVER ?',
            ans: `To become a driver, register on the Shesha app with the necessary documents, including your ID, Driver's License with PRDP, Vehicle License, and Vehicle Inspection Report.`
        },
        {
            q: 'WHAT ARE THE VEHICLE REQUIREMENTS FOR SHESHA DRIVERS?',
            ans: `We accept vehicles no older than the 2015 model in good condition. All vehicles must pass roadworthiness and shuttle evaluation tests to operate on Shesha.`
        },
        {
            q: 'HOW DO I GET PAID AS A SHESHA DRIVER ?',
            ans: `All your earnings from rides are credited to your Shesha driver wallet. You can withdraw your earnings anytime either to your bank account or through cardless withdrawal at any bank.`
        },
        {
            q: 'HOW DOES THE RATING SYSTEM WORK ?',
            ans: `After every trip, both drivers and customers are encouraged to rate each other. This helps us maintain the quality of service and address any areas of improvement.`
        },
        {
            q: 'WHAT IF I FACE AN ISSUE DURING MY RIDE ?',
            ans: `Please report any issues immediately through the app or contact our customer support. We are committed to ensuring a positive experience for all users.`
        }
    ];

    const price = [
        {
            q: 'DO YOU HAVE SURGE PRICING LIKE OTHER E-HAILING PLATFORMS ?',
            ans: `No, Shesha believes in consistent pricing. Our costs remain fixed regardless of time, season, or busyness.`
        },
        {
            q: 'CAN I MAKE A PAYMENT USING CASH ?',
            ans: `No, all payments are made through the Shesha wallet to ensure seamless and safe transactions for both drivers and customers.`
        },
        {
            q: 'IS THERE A MINIMUM AMOUNT I NEED TO HAVE IN MY WALLET TO BOOK A RIDE ?',
            ans: `Your wallet should have enough funds to cover the estimated cost of your journey. If the balance is too low, you'll be prompted to top up before booking.`
        }
    ];

    const related = [
        {
            q: 'HOW ARE DRIVERS TRAINED OR VETTED ?',
            ans: `All our drivers undergo a thorough verification and training process. They must provide essential documents, and only after passing our evaluation do they become a part of Shesha.`
        },
        {
            q: 'DO DRIVERS SEE MY PERSONAL CONTACT DETAILS?',
            ans: `No, to ensure privacy, all communications between drivers and riders are facilitated through the app without revealing personal contact details.`
        }
    ];

    const tech = [
        {
            q: "I'M HAVING TROUBLE WITH THE APP. WHAT SHOULD I DO ?",
            ans: "Ensure you have the latest version of the app. If the problem persists, contact our support team through the app or our website."
        },
        {
            q: "I WAS INCORRECTLY CHARGED FOR A RIDE. HOW DO I GET A REFUND?",
            ans: "Please report the issue through the app's support feature, providing all necessary details. Our team will review and process any valid refunds promptly."
        },
        {
            q: "WHAT HAPPENS IF THERE'S AN ACCIDENT OR EMERGENCY DURING MY RIDE?",
            ans: "Your safety is paramount. In the event of an emergency, ensure you're safe and contact local authorities immediately. Once that's done, please report the incident through the app so we can take appropriate action."
        }
    ];

    const policy = [
        {
            q: "ARE PETS ALLOWED ON SHESHA RIDES ?",
            ans: "No, pets are not allowed except for service animals."
        },
        {
            q: "WHAT'S SHESHA'S POLICY ON LOST ITEMS ?",
            ans: "If you forget an item in a Shesha vehicle, please report it immediately via the app. We'll help coordinate with the driver to return your item, but Shesha cannot be held responsible for lost items."
        },
        {
            q: "HOW DOES SHESHA ENSURE INCLUSIVITY AND NON-DISCRIMINATION ?",
            ans: "Shesha is committed to serving everyone equally. We have a strict non-discrimination policy and ensure that all customers, regardless of race, gender, or nationality, are treated with respect and dignity."
        }
    ];

    return (
        <div>

            <div className="lg:min-h-[80vh] min-h-[50vh] bg-cover  bg-no-repeat pt-5 " style={{ backgroundImage: `url(${HomeTop})`, boxShadow: 'rgba(0, 0, 0, 0.6) 500px 5000px inset', }} >
                <Slide direction='down' triggerOnce>
                    <div className="text-center pt-10 tracking-wider  font-extrabold">
                        <Fade triggerOnce delay={300} duration={300} cascade className='text-3xl md:text-6xl lg:text-7xl font-bold text-white '>
                            Frequently Asked

                        </Fade>
                        <Fade delay={2800} triggerOnce duration={800} cascade className='text-3xl ms-2  md:text-6xl lg:text-7xl font-bold text-[#f62f02] '>
                            Question
                        </Fade>
                    </div>
                </Slide>
            </div>
            <section className="lg:min-h-[90vh] min-h-[10vh] bg-cover  bg-no-repeat pt-5 " style={{ backgroundImage: `url(${str1})`, backgroundPosition: 'center' }}>
                <div className="py-8 text-center">
                    <h1 className='font-bold lg:text-3xl  uppercase'>customers</h1>
                    <div className="flex justify-center flex-col space-y-5 lg:space-y-12  items-center mt-14 px-5 lg:px-0">
                        {
                            data.map(e => {
                                return (
                                    <Accordion q={e.q} answer={e.ans}
                                        className='lg:max-w-[70vw]'
                                    />
                                )
                            }
                            )

                        }

                    </div>
                </div>
            </section>
            <section className="lg:min-h-[70vh] min-h-[auto] bg-cover  bg-no-repeat lg:pt-5 " >
                <div className="py-8 text-center">
                    <h1 className='font-bold lg:text-3xl uppercase'>Drivers</h1>
                    <div className="flex justify-center flex-col space-y-5 lg:space-y-12 items-center mt-8 px-5 lg:px-0">
                        {
                            driver.map(e => {
                                return (
                                    <Accordion q={e.q} answer={e.ans}
                                        className='lg:max-w-[70vw]'
                                    />
                                )
                            }
                            )

                        }

                    </div>
                </div>
            </section>
            <section className="lg:min-h-[80vh] min-h-[10vh] bg-cover  bg-no-repeat lg:pt-10 " style={{ backgroundImage: `url(${str2})`, backgroundPosition: 'center', backgroundSize: '100%' }} >
                <div className="py-8 text-center">
                    <h1 className='font-bold lg:text-3xl uppercase'>Payments & pricing</h1>
                    <div className="flex justify-center flex-col space-y-5 lg:space-y-12 items-center mt-8 px-5 lg:px-0">
                        {
                            price.map(e => {
                                return (
                                    <Accordion q={e.q} answer={e.ans}
                                        className='lg:max-w-[70vw]'
                                    />
                                )
                            }
                            )

                        }

                    </div>
                </div>
            </section>
            <section className=" " >
                <div className="py-8 text-center">
                    <h1 className='font-bold lg:text-3xl uppercase'>DRIVER-RELATED</h1>
                    <div className="flex justify-center flex-col space-y-5 lg:space-y-12 items-center mt-8 px-5 lg:px-0">
                        {
                            related.map(e => {
                                return (
                                    <Accordion q={e.q} answer={e.ans}
                                        className='lg:max-w-[70vw]'
                                    />
                                )
                            }
                            )

                        }

                    </div>
                </div>
            </section>
            <section className="lg:min-h-[50vh] min-h-[10vh] bg-cover  bg-no-repeat lg:pt-5  "  >
                <div className="py-8 text-center">
                    <h1 className='font-bold lg:text-3xl uppercase'>TROUBLESHOOTING & SUPPORT</h1>
                    <div className="flex justify-center flex-col space-y-5 lg:space-y-12 items-center mt-8 px-5 lg:px-0">
                        {
                            tech.map(e => {
                                return (
                                    <Accordion q={e.q} answer={e.ans}
                                        className='lg:max-w-[70vw]'
                                    />
                                )
                            }
                            )
                        }

                    </div>
                </div>
            </section>
            <section className="lg:min-h-[80vh] min-h-[50vh] bg-cover   bg-no-repeat lg:pt-5 " style={{ backgroundImage: `url(${str3})`, backgroundPosition: 'center' }} >
                <div className="py-8 text-center">
                    <h1 className='font-bold lg:text-3xl uppercase'>POLICIES & GUIDELINES</h1>
                    <div className="flex justify-center flex-col space-y-5 lg:space-y-12 items-center mt-8 px-5 lg:px-0" >
                        {
                            policy.map(e => {
                                return (
                                    <Accordion q={e.q} answer={e.ans}
                                        className='lg:max-w-[70vw]'
                                    />
                                )
                            }
                            )
                        }

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Faq;