import React, { useEffect } from 'react';

const DriverMenuWidget = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://widget.simplybook.me/v2/widget/widget.js';
        script.async = true;
        script.onload = () => {
          window.SimplybookWidget = new window.SimplybookWidget({
            widget_type: 'iframe',
            url: 'https://sheshaehailingservices.simplybook.me',
            theme: 'blur',
            theme_settings: {
              timeline_hide_unavailable: '1',
              hide_past_days: '0',
              timeline_show_end_time: '0',
              timeline_modern_display: 'as_slots',
              sb_base_color: '#4f5f6c',
              display_item_mode: 'block',
              body_bg_color: '#f6f6f8',
              dark_font_color: '#212528',
              light_font_color: '#ffffff',
              btn_color_1: '#b9c4ce',
              sb_company_label_color: '#283037',
              hide_img_mode: '1',
              sb_busy: '#c7b3b3',
              sb_available: '#ebddde',
            },
            timeline: 'modern',
            datepicker: 'top_calendar',
            is_rtl: false,
            app_config: {
              clear_session: 0,
              allow_switch_to_ada: 0,
              predefined: [],
            },container_id:"sbw_1ys1go",
          });
        };
        document.head.appendChild(script);
        return () => {
          document.head.removeChild(script);
      };
      }, []);
  return (
      <div id="sbw_1ys1go"></div>
  );
};

export default DriverMenuWidget;
