import happyMoments1 from '../../assets/Events/Hday1.jpeg';
import happyMoments2 from '../../assets/Events/Hday2.jpeg';
import happyMoments3 from '../../assets/Events/Hday3.jpeg';
import happyMoments4 from '../../assets/Events/Hday4.jpeg';
import happyMoments5 from '../../assets/Events/Hday5.jpeg';
import happyMoments6 from '../../assets/Events/Hday6.jpeg';
import happyMoments7 from '../../assets/Events/Hday7.jpeg';
import happyMoments8 from '../../assets/Events/Hday8.jpeg';
import happyMoments9 from '../../assets/Events/Hday9.jpeg';
import happyMoments10 from '../../assets/Events/Hday10.jpeg';
import happyMoments11 from '../../assets/Events/Hday11.jpeg';
import happyMoments12 from '../../assets/Events/Hday12.jpeg';
import happyMoments13 from '../../assets/Events/Hday13.jpeg';
import happyMoments14 from '../../assets/Events/Hday14.jpeg';
import SheshaEventHday from '../../assets/Events/SheshaEventHday.jpeg';

import happyMomentsThumbnail1 from '../../assets/Events/EventsThumbnailimage/Hday1.jpeg';
import happyMomentsThumbnail2 from '../../assets/Events/EventsThumbnailimage/Hday2.jpeg';
import happyMomentsThumbnail3 from '../../assets/Events/EventsThumbnailimage/Hday3.jpeg';
import happyMomentsThumbnail4 from '../../assets/Events/EventsThumbnailimage/Hday4.jpeg';
import happyMomentsThumbnail5 from '../../assets/Events/EventsThumbnailimage/Hday5.jpeg';
import happyMomentsThumbnail6 from '../../assets/Events/EventsThumbnailimage/Hday6.jpeg';
import happyMomentsThumbnail7 from '../../assets/Events/EventsThumbnailimage/Hday7.jpeg';
import happyMomentsThumbnail8 from '../../assets/Events/EventsThumbnailimage/Hday8.jpeg';
import happyMomentsThumbnail9 from '../../assets/Events/EventsThumbnailimage/Hday9.jpeg';
import happyMomentsThumbnail10 from '../../assets/Events/EventsThumbnailimage/Hday10.jpeg';
import happyMomentsThumbnail11 from '../../assets/Events/EventsThumbnailimage/Hday11.jpeg';
import happyMomentsThumbnail12 from '../../assets/Events/EventsThumbnailimage/Hday12.jpeg';
import happyMomentsThumbnail13 from '../../assets/Events/EventsThumbnailimage/Hday13.jpeg';
import happyMomentsThumbnail14 from '../../assets/Events/EventsThumbnailimage/Hday14.jpeg';

// const Events = [
//     {
//         id:1,
//         img: happyMoments1,
//         imgTb: happyMomentsThumbnail1
//     },
//     {
//         id:2,
//         img: happyMoments2,
//         imgTb: happyMomentsThumbnail2
//     },
//     {
//         id:3,
//         img: happyMoments3
//     },
//     {
//         id:4,
//         img: happyMoments4
//     },
//     {
//         id:5,
//         img: happyMoments5
//     },
//     {
//         id:6,
//         img: happyMoments6
//     },
//     {
//         id:7,
//         img: happyMoments7
//     },
//     {
//         id:8,
//         img: happyMoments8
//     },
//     {
//         id:9,
//         img: happyMoments9
//     },
//     {
//         id:10,
//         img: happyMoments10
//     },
//     {
//         id:11,
//         img: happyMoments11
//     },
//     {
//         id:12,
//         img: happyMoments12
//     },
//     {
//         id:13,
//         img: happyMoments13
//     },
//     {
//         id:14,
//         img: happyMoments14
//     }

// ]
const Events = [
    {
        id: 1,
        img: happyMoments1,
        imgTb: happyMomentsThumbnail1,
        imgEvents:SheshaEventHday
    },
    {
        id: 2,
        img: happyMoments2,
        imgTb: happyMomentsThumbnail2
    },
    {
        id: 3,
        img: happyMoments3,
        imgTb: happyMomentsThumbnail3
    },
    {
        id: 4,
        img: happyMoments4,
        imgTb: happyMomentsThumbnail4
    },
    {
        id: 5,
        img: happyMoments5,
        imgTb: happyMomentsThumbnail5
    },
    {
        id: 6,
        img: happyMoments6,
        imgTb: happyMomentsThumbnail6
    },
    {
        id: 7,
        img: happyMoments7,
        imgTb: happyMomentsThumbnail7
    },
    {
        id: 8,
        img: happyMoments8,
        imgTb: happyMomentsThumbnail8
    },
    {
        id: 9,
        img: happyMoments9,
        imgTb: happyMomentsThumbnail9
    },
    {
        id: 10,
        img: happyMoments10,
        imgTb: happyMomentsThumbnail10
    },
    {
        id: 11,
        img: happyMoments11,
        imgTb: happyMomentsThumbnail11
    },
    {
        id: 12,
        img: happyMoments12,
        imgTb: happyMomentsThumbnail12
    },
    {
        id: 13,
        img: happyMoments13,
        imgTb: happyMomentsThumbnail13
    },
    {
        id: 14,
        img: happyMoments14,
        imgTb: happyMomentsThumbnail14
    }
];


export default Events;