import React, { useEffect } from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import '../App.css';
import DriverR from '../assets/Driver Requirements.png';
// import Fair from '../assets/Fair payment.png';
// import ResI from '../assets/Respectful.png';
// import Safe from '../assets/Safety.png';
import Lady from '../assets/driver-boy.png';
import STR from '../assets/driverStr.png';
import HomeTop from '../assets/driverT.jpg';
import StartDriver from '../assets/rready.png';
// import Trans from '../assets/transparency.png';
import respectfulEnvironment from '../assets/respectfulEnvironment.png';
import transparentPolicies from '../assets/transparentPolicies.png';
import fairCompensation from '../assets/fairCompensation.png';
import safetyFirst from '../assets/safetyFirst.png';
import DriverMenuWidget from './DriverMenuWidget';




function ForDriver() {
    const card = [
        {
            img: fairCompensation,
            head: 'FAIR COMPENSATION',
            p1: 'We deeply value our drivers and are committed to fair and transparent payment structures. Competitive earnings, low commission rates, and no hidden fees business practices that benefit everyone. That’s why we charge only a 20% commission, ensuring you keep more of your hard-earned money.',
            p2: "There are no hidden fees—what you see in your wallet is entirely yours."
        }, {
            img: respectfulEnvironment,
            head: 'RESPECTFUL ENVIRONMENT',
            p1: 'We encourage riders to treat our drivers with the utmost respect and courtesy.',
            p2: "Our rating system and feedback mechanisms ensure that your voice is heard and valued."
        },
        {
            img: transparentPolicies,
            head: 'TRANSPARENT POLICIES',
            p1: "- Our policies are clear, straightforward, and designed with your best interests in mind. From pricing to payment, everything is transparent and easy to understand.",
            p2: "    "
        },
        {
            img: safetyFirst,
            head: 'SAFETY FIRST',
            p1: 'Your safety is our top priority. With features like identity verification, we ensure you are protected at every step of your journey.',
            p2: "       "
        }
    ]
    const licenseDatas = [
        // {
        //     id: '1',
        //     data: 'Drivers Licence with PDP'
        // },
        {
            id: '1',
            data: 'Vehicle Inspection Report'
        },
        // {
        //     id: '3',
        //     data: 'Vehicle License'
        // },
        // {
        //     id: '4',
        //     data: 'Certified South African Identity Document Copy'
        // },
        {
            id: '2',
            data: 'Driver ID'
        },
        {
            id: '3',
            data: 'Vehicle Registration Certificate'
        },
        {
            id: '4',
            data: 'Roadworthy certificate'
        },
        {
            id: '5',
            data: ' License With PRDP (Professional Driving Permit)'
        },
        {
            id: '6',
            data: 'Proof Of Address'
        },
        {
            id: '7',
            data: ' Right image of the car'
        },
        {
            id: '8',
            data: ' Left image of the car'
        },
        {
            id: '9',
            data: 'Front image of the car'
        },
        {
            id: '10',
            data: 'Back image of the car'
        },
        {
            id: '11',
            data: 'Previous e-hailing record (if applicable)'
        }
    ]



    // var widget = new
    // SimplybookWidget({"widget_type":"iframe","url":"https:\/\/sheshaehailingservices.simplybook.me","theme":"blur","theme_settings":{"timeline_hide_unavailable":"1","hide_past_days":"0","timeline_show_end_time":"0","timeline_modern_display":"as_slots","sb_base_color":"#4f5f6c","display_item_mode":"block","body_bg_color":"#f6f6f8","dark_font_color":"#212528","light_font_color":"#ffffff","btn_color_1":"#b9c4ce","sb_company_label_color":"#283037","hide_img_mode":"1","sb_busy":"#c7b3b3","sb_available":"#ebddde"},"timeline":"modern","datepicker":"top_calendar","is_rtl":false,"app_config":{"clear_session":0,"allow_switch_to_ada":0,"predefined":[]}});
    return (
        <div >
            <div className="lg:min-h-[80vh] min-h-[50vh] bg-cover bg-no-repeat  " style={{ backgroundImage: `url(${HomeTop})`, boxShadow: 'rgba(0, 0, 0, 0.5) 500px 2000px inset', }} >
                <Slide direction='down' triggerOnce>
                    <div className="text-center pt-10">
                        <h1 className='text-3xl md:text-6xl lg:text-8xl font-bold text-white'>Refine Your Journey
                            <br />
                            <Fade delay={1000} triggerOnce duration={1000} cascade>
                                <div className='text-[#f62f02] font-bold lg:pt-3 text-2xl md:text-6xl lg:text-7xl '>

                                    Driver  <span className='text-white'>With</span>    Shesha!
                                </div>
                            </Fade>

                        </h1>
                    </div>
                </Slide>
            </div>
            <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 px-5 md:px-12 lg:px-20  items-center p-5'>
                <Slide direction='left' className='' triggerOnce>
                    <div className="p-2">
                        <h3 className='text-black text-lg md:text-3xl lg:text-5xl font-extrabold'>
                            WHY  <span className='text-[#f62f02]'> DRIVE</span> WITH
                            <br />
                            <span className='text-[#f62f02]'> SHESHA?</span>
                        </h3>
                        <div className="text-lg my-5 md:my-8 lg:my-10 flex items-center flex-col justify-center">
                            <p className='' >
                                At Shesha, we believe drivers are the cornerstone of our service. We've built a platform that values your contribution, respects your efforts, and supports your journey at every turn. If you are looking for a community that cares about your well-being and provides fair compensation, then Shesha is the place for you.
                            </p>
                            <br />

                        </div>

                    </div>
                </Slide>
                <Slide direction='right' triggerOnce>
                    <div className="">
                        <img src={Lady} alt="" />
                    </div>
                </Slide>


            </section>
            {/* card section */}
            <section className='min-h-[90vh] -z-10 flex justify-center items-start' style={{ background: `url(${STR}) `, backgroundPosition: 'center' }}>x

                <div className="grid md:grid-cols-2 lg:grid-cols-4 place-items-start gap-5">
                    {card.map((e, i) => {
                        return (
                            <Fade direction='up' cascade delay={i * 150} triggerOnce>
                                <div className="card relative max-w-[18rem] border-2 p-10  my-5 lg:my-0  rounded-lg bg-white shadow-2xl">
                                    <div className="absolute-div     ">
                                        <img src={e.img} alt="" className=' p-4 text-center h-[80px] bg-[#f62f02] rounded-full  ' />
                                    </div>
                                    <div className="text-center pt-8">
                                        <h1 className='pb-2 font-extrabold'>{e.head}</h1>
                                        <p className='font-medium '>{e.p1}</p>
                                        <p className='font-medium py-3'>{e.p2}</p>
                                    </div>

                                </div>
                            </Fade>
                        )
                    })}
                </div>

            </section>
            {/* driver section */}
            <section className='grid grid-cols-1  md:grid-cols-2 lg:grid-cols-2 mt-5  items-start lg:items-center '>

                <Slide direction='left' triggerOnce>
                    <div className="">
                        <img src={DriverR} alt="" />
                    </div>
                </Slide>
                <Slide direction='right' className='' triggerOnce>
                    <div className="p-2">
                        <h3 className='text-black text-2xl md:text-3xl lg:text-5xl font-extrabold ms-4 lg:ms-0'>
                            DRIVER  <br /> <span className='text-[#f62f02]'> REQUIREMENTS</span>
                        </h3>
                        <div className="text-lg my-5 md:my-8 lg:my-10 flex items-center flex-col justify-center p-5 space-y-8 ">
                            <p className=''>To become a Shesha driver, you'll need the following documents: These documents are required to activate your account.</p>
                            <p className='' >
                                Additionally, your vehicle must be a 2015 model or newer and in decent condition.
                            </p>
                            <p className=''>Only vehicles that have passed the vehicle roadworthiness and shuttle evaluations tests will be permitted to operate on Shesha.</p>
                        </div>
                        {(() => {
                            const renderItems = (data) =>
                                data.map((e, i) => (
                                    <Fade duration={i * 500} cascade triggerOnce>
                                        <li key={i} className="text-lg ">
                                            <div className="flex items-center gap-3 bg-white">
                                                <div className="border-[#f62f02] border-2 rounded-full">
                                                    <p className="m-1 text-white rounded-full px-3 py-1 bg-[#f62f02] font-bold">{e.id}</p>
                                                </div>
                                                <p>{e.data}</p>
                                            </div>
                                        </li>
                                    </Fade>
                                ));

                            return licenseDatas.length > 7 ? (
                                <div className="grid grid-cols-2 gap-4">
                                    <ul className="space-y-3">{renderItems(licenseDatas.slice(0, 7))}</ul>
                                    <ul className="space-y-3">{renderItems(licenseDatas.slice(7))}</ul>
                                </div>
                            ) : (
                                <ul className="space-y-3">{renderItems(licenseDatas)}</ul>
                            );
                        })()}
                    </div>
                </Slide>


            </section>
            <section className='lg:min-h-[90vh] min-h-[40vh]   bg-contain  mt-5  flex justify-center items-end' style={{ background: `url(${StartDriver}) `, boxShadow: 'rgba(0, 0, 0, 0.5) 500px 2000px inset' }}>
                <div className="text-center text-white p-10 space-y-8  lg:max-w-[70%] mb-10 ">
                    <h2 className='text-2xl md:text-3xl lg:text-5xl font-extrabold '>READY TO DRIVE WITH DIGNITY?</h2>
                    <p className='text-lg md:text-2xl lg:text-3xl font-bold '>Register today to become a Shesha driver and join a community that values and respects you. Click the link below to start your
                        application process.</p>
                    <a href="https://play.google.com/store/apps/details?id=com.shesha.driver.elite&hl=en&gl=US" target='_blank' className='pt-3 mt-5 block'>
                        <button className='text-lg px-5 py-3 font-extrabold bg-white text-black'>REGISTER <span className='text-[#f62f02] '>NOW</span> </button>
                    </a>
                </div>

            </section>
            <section>
                <div className='text-center p-2 lg:text-2xl text-xl font-bold '>
                Book Your Vehicle Inspection Today
                </div>
                <DriverMenuWidget />
            </section>
            {/* <iframe scrolling="no" class="sb-widget-iframe" width="100%" border="0" frameborder="0" src="https://sheshaehailingservices.simplybook.me/v2/?widget-type=iframe&amp;theme=blur&amp;theme=blur&amp;timeline=modern&amp;datepicker=top_calendar" name="widget_0.3801591223860621" id="widget_0.3801591223860621" title="Booking widget" height="100"></iframe> */}
        </div>
    )
}

export default ForDriver