import React, { useEffect, useRef, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { RiCloseCircleLine, RiMenu3Fill } from "react-icons/ri";
import { NavLink, useNavigate } from 'react-router-dom';
import '../App.css';
import Logo from '../assets/logo.png';

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const headerRef = useRef(null);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!headerRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);
    useEffect(() => {
        setIsOpen(false)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [navigate])
    const CustomNavLink = ({ exact, to, children }) => {
        return (
            // <li className="cursor-pointer hover:text-[#f62f02]" onClick={() => navigate(to)}>
            <NavLink
                exact={exact}
                to={to}
                className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active-header" : ""
                }
            >
                {children}
            </NavLink>
            // </li>
        );
    };

    return (
        <div className='relative z-20  shadow-2xl w-full'>
            <div className="bg-white flex justify-between lg:justify-center px-8 items-center ">
                <div className="flex justify-center gap-40 items-end my-2 mb-3">

                    <div className='cursor-pointer' onClick={() => navigate('/')}>
                        <img src={Logo} alt="" className=" h-[50px] md:h-[65px] lg:[80px] " />
                    </div>
                    <Fade cascade damping={0.2} triggerOnce delay={1000}>
                        <ul className='lg:flex hidden md:hidden text-sm   list-none  space-x-8 items-baseline font-[650]'>

                            <CustomNavLink exact to={'/'}>HOME</CustomNavLink>
                            <CustomNavLink exact to={'/AboutUs'}>ABOUT US</CustomNavLink>
                            <CustomNavLink exact to={'/ForDrivers'}>DRIVER</CustomNavLink>

                            <CustomNavLink exact to={'/ForCustomer'}>CUSTOMER</CustomNavLink>
                            <CustomNavLink exact to={'/Gallery'}>GALLERY</CustomNavLink>
                            <CustomNavLink exact to={'/Blog'}>BLOG</CustomNavLink>
                            <CustomNavLink exact to={'/Faq'}>FAQ</CustomNavLink>
                            <CustomNavLink exact to={'/Contact'}>CONTACT US</CustomNavLink>
                        </ul>
                    </Fade>

                </div>
                <div className="" onClick={toggleMenu}  >
                    {
                        isOpen ? <RiCloseCircleLine className='text-black text-2xl block md:block lg:hidden font-bold' /> : <RiMenu3Fill className='text-black text-2xl block md:block lg:hidden font-bold' />
                    }


                </div>


            </div>
            <div ref={headerRef}
                className={`lg:hidden absolute  z-0  right-0    transition-all duration-500 origin-top-right w-[70%] md:w-[40%] min-h-[300vh] bg-white  ${isOpen ? 'translate-x-0' : 'translate-x-72 hidden'
                    }`}
            >

                <Fade cascade direction='right' duration={300} className='text-sm  text-center list-none mt-8 justify-center flex flex-col items-center  space-y-8 font-[650]'>
                    <CustomNavLink exact to={'/'}>HOME</CustomNavLink>
                    <CustomNavLink exact to={'/AboutUs'}>ABOUT US</CustomNavLink>
                    <CustomNavLink exact to={'/ForDrivers'}>DRIVER</CustomNavLink>
                    <CustomNavLink exact to={'/ForCustomer'}>CUSTOMER</CustomNavLink>
                    <CustomNavLink exact to={'/Gallery'}>GALLERY</CustomNavLink>
                    <CustomNavLink exact to={'/Blog'}>BLOG</CustomNavLink>
                    <CustomNavLink exact to={'/Faq'}>FAQ</CustomNavLink>
                    <CustomNavLink exact to={'/Contact'}>CONTACT US</CustomNavLink>
                </Fade>
            </div>
        </div>
    )
}

export default Navbar