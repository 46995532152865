import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import DeleteAccount from './components/DeleteAccount';
import Faq from './components/Faq';
import Footer from './components/Footer';
import Gallery from './components/Gallery';
import Blog from './components/Blog';
import ForCustomer from './components/ForCustomer';
import ForDriver from './components/ForDriver';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Verify from './components/Verify';

function App() {
  const urlMethod = window.location.href.split("/")[3];
  console.log(urlMethod);
  return (

    <BrowserRouter>
      {
        urlMethod == 'verifiedDriver' ? null : <Navbar />
      }

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="ForDrivers" element={<ForDriver />} />
        <Route path="AboutUs" element={<AboutUs />} />
        <Route path="ForCustomer" element={<ForCustomer />} />
        <Route path="Gallery" element={<Gallery />} />
        <Route path="Blog" element={<Blog />} />
        <Route path="Faq" element={<Faq />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="Privacy" element={<Privacy />} />
        <Route path="DeleteAccount" element={<DeleteAccount />} />
        <Route path="TermsofUse" element={<Terms />} />
        <Route path="verifiedDriver" element={<Verify />} />
      </Routes>
      {
        urlMethod == 'verifiedDriver' ? null : <Footer />
      }

    </BrowserRouter>
  );
}

export default App;
