
import Launch1 from '../../assets/Launch/Launch1.jpeg';
import Launch2 from '../../assets/Launch/Launch2.jpeg';
import Launch3 from '../../assets/Launch/Launch3.jpeg';
import Launch4 from '../../assets/Launch/Launch4.jpg';
import Launch5 from '../../assets/Launch/Launch5.jpeg';
import Launch6 from '../../assets/Launch/Launch6.jpg';
import Launch7 from '../../assets/Launch/Launch7.jpg';
import Launch8 from '../../assets/Launch/Launch8.jpg';
import Launch9 from '../../assets/Launch/Launch9.jpeg';
import Launch10 from '../../assets/Launch/Launch10.jpg';
import Launch11 from '../../assets/Launch/Launch11.jpg';
import Launch12 from '../../assets/Launch/Launch12.jpg';
import Launch13 from '../../assets/Launch/Launch13.jpg';
import Launch14 from '../../assets/Launch/Launch14.jpg';
import Launch15 from '../../assets/Launch/Launch15.jpg';
import Launch16 from '../../assets/Launch/Launch16.jpg';
import Launch17 from '../../assets/Launch/Launch17.jpg';
import Launch18 from '../../assets/Launch/Launch18.jpg';
import Launch19 from '../../assets/Launch/Launch19.jpg';
import Launch20 from '../../assets/Launch/Launch20.jpg';
import Launch21 from '../../assets/Launch/Launch21.jpg';
import Launch22 from '../../assets/Launch/Launch22.jpg';
import Launch23 from '../../assets/Launch/Launch23.jpg';
import Launch24 from '../../assets/Launch/Launch24.jpg';
import Launch25 from '../../assets/Launch/Launch25.jpg';
import Launch26 from '../../assets/Launch/Launch26.jpg';
import Launch27 from '../../assets/Launch/Launch27.jpg';
import Launch28 from '../../assets/Launch/Launch28.jpg';
import Launch29 from '../../assets/Launch/Launch29.jpg';
import Launch30 from '../../assets/Launch/Launch30.jpg';
import Launch31 from '../../assets/Launch/Launch31.jpg';
import Launch32 from '../../assets/Launch/Launch32.jpg';
import Launch33 from '../../assets/Launch/Launch33.jpg';
import Launch34 from '../../assets/Launch/Launch34.jpg';
import Launch35 from '../../assets/Launch/Launch35.jpg';
import Launch36 from '../../assets/Launch/Launch36.jpg';
import Launch37 from '../../assets/Launch/Launch37.jpg';
import Launch38 from '../../assets/Launch/Launch38.jpg';
import Launch39 from '../../assets/Launch/Launch39.jpg';
import Launch40 from '../../assets/Launch/Launch40.jpg';
import Launch41 from '../../assets/Launch/Launch41.jpg';
import Launch42 from '../../assets/Launch/Launch42.jpg';
import Launch43 from '../../assets/Launch/Launch43.jpg';
import Launch44 from '../../assets/Launch/Launch44.jpg';
import Launch45 from '../../assets/Launch/Launch45.jpg';
import Launch46 from '../../assets/Launch/Launch46.jpg';
import Launch47 from '../../assets/Launch/Launch47.jpg';
import Launch48 from '../../assets/Launch/Launch48.jpg';
import Launch49 from '../../assets/Launch/Launch49.jpg';
import Launch50 from '../../assets/Launch/Launch50.jpg';
import Launch51 from '../../assets/Launch/Launch51.jpg';
import Launch52 from '../../assets/Launch/Launch52.jpg';
import Launch53 from '../../assets/Launch/Launch53.jpg';
import Launch54 from '../../assets/Launch/Launch54.jpg';
import Launch55 from '../../assets/Launch/Launch55.jpg';
import Launch56 from '../../assets/Launch/Launch56.jpg';
import Launch57 from '../../assets/Launch/Launch57.jpg';
import Launch58 from '../../assets/Launch/Launch58.jpg';
import Launch59 from '../../assets/Launch/Launch59.jpg';
import Launch60 from '../../assets/Launch/Launch60.jpg';
import Launch61 from '../../assets/Launch/Launch61.jpg';
import Launch62 from '../../assets/Launch/Launch62.jpg';
import Launch63 from '../../assets/Launch/Launch63.jpg';
import Launch64 from '../../assets/Launch/Launch64.jpg';
import Launch65 from '../../assets/Launch/Launch65.jpg';
import Launch66 from '../../assets/Launch/Launch66.jpg';
import Launch67 from '../../assets/Launch/Launch67.jpg';
import Launch68 from '../../assets/Launch/Launch68.jpg';
import Launch69 from '../../assets/Launch/Launch69.jpg';
import Launch70 from '../../assets/Launch/Launch70.jpg';
import Launch71 from '../../assets/Launch/Launch71.jpg';
import Launch72 from '../../assets/Launch/Launch72.jpg';
import Launch73 from '../../assets/Launch/Launch73.jpg';
import Launch74 from '../../assets/Launch/Launch74.jpg';
import Launch75 from '../../assets/Launch/Launch75.jpg';
import Launch76 from '../../assets/Launch/Launch76.jpg';
import Launch77 from '../../assets/Launch/Launch77.jpg';
import Launch78 from '../../assets/Launch/Launch78.jpg';
import HappyMovementsLaunch from '../../assets/Launch/HappyMovementsLaunch.jpg';

// Launch Thumbnail Image Imports
import LaunchThumbnail1 from '../../assets/Launch/LaunchThumbnailimage/Launch1.jpeg';
import LaunchThumbnail2 from '../../assets/Launch/LaunchThumbnailimage/Launch2.jpeg';
import LaunchThumbnail3 from '../../assets/Launch/LaunchThumbnailimage/Launch3.jpeg';
import LaunchThumbnail4 from '../../assets/Launch/LaunchThumbnailimage/Launch4.jpg';
import LaunchThumbnail5 from '../../assets/Launch/LaunchThumbnailimage/Launch5.jpeg';
import LaunchThumbnail6 from '../../assets/Launch/LaunchThumbnailimage/Launch6.jpg';
import LaunchThumbnail7 from '../../assets/Launch/LaunchThumbnailimage/Launch7.jpg';
import LaunchThumbnail8 from '../../assets/Launch/LaunchThumbnailimage/Launch8.jpg';
import LaunchThumbnail9 from '../../assets/Launch/LaunchThumbnailimage/Launch9.jpeg';
import LaunchThumbnail10 from '../../assets/Launch/LaunchThumbnailimage/Launch10.jpg';
import LaunchThumbnail11 from '../../assets/Launch/LaunchThumbnailimage/Launch11.jpg';
import LaunchThumbnail12 from '../../assets/Launch/LaunchThumbnailimage/Launch12.jpg';
import LaunchThumbnail13 from '../../assets/Launch/LaunchThumbnailimage/Launch13.jpg';
import LaunchThumbnail14 from '../../assets/Launch/LaunchThumbnailimage/Launch14.jpg';
import LaunchThumbnail15 from '../../assets/Launch/LaunchThumbnailimage/Launch15.jpg';
import LaunchThumbnail16 from '../../assets/Launch/LaunchThumbnailimage/Launch16.jpg';
import LaunchThumbnail17 from '../../assets/Launch/LaunchThumbnailimage/Launch17.jpg';
import LaunchThumbnail18 from '../../assets/Launch/LaunchThumbnailimage/Launch18.jpg';
import LaunchThumbnail19 from '../../assets/Launch/LaunchThumbnailimage/Launch19.jpg';
import LaunchThumbnail20 from '../../assets/Launch/LaunchThumbnailimage/Launch20.jpg';
import LaunchThumbnail21 from '../../assets/Launch/LaunchThumbnailimage/Launch21.jpg';
import LaunchThumbnail22 from '../../assets/Launch/LaunchThumbnailimage/Launch22.jpg';
import LaunchThumbnail23 from '../../assets/Launch/LaunchThumbnailimage/Launch23.jpg';
import LaunchThumbnail24 from '../../assets/Launch/LaunchThumbnailimage/Launch24.jpg';
import LaunchThumbnail25 from '../../assets/Launch/LaunchThumbnailimage/Launch25.jpg';
import LaunchThumbnail26 from '../../assets/Launch/LaunchThumbnailimage/Launch26.jpg';
import LaunchThumbnail27 from '../../assets/Launch/LaunchThumbnailimage/Launch27.jpg';
import LaunchThumbnail28 from '../../assets/Launch/LaunchThumbnailimage/Launch28.jpg';
import LaunchThumbnail29 from '../../assets/Launch/LaunchThumbnailimage/Launch29.jpg';
import LaunchThumbnail30 from '../../assets/Launch/LaunchThumbnailimage/Launch30.jpg';
import LaunchThumbnail31 from '../../assets/Launch/LaunchThumbnailimage/Launch31.jpg';
import LaunchThumbnail32 from '../../assets/Launch/LaunchThumbnailimage/Launch32.jpg';
import LaunchThumbnail33 from '../../assets/Launch/LaunchThumbnailimage/Launch33.jpg';
import LaunchThumbnail34 from '../../assets/Launch/LaunchThumbnailimage/Launch34.jpg';
import LaunchThumbnail35 from '../../assets/Launch/LaunchThumbnailimage/Launch35.jpg';
import LaunchThumbnail36 from '../../assets/Launch/LaunchThumbnailimage/Launch36.jpg';
import LaunchThumbnail37 from '../../assets/Launch/LaunchThumbnailimage/Launch37.jpg';
import LaunchThumbnail38 from '../../assets/Launch/LaunchThumbnailimage/Launch38.jpg';
import LaunchThumbnail39 from '../../assets/Launch/LaunchThumbnailimage/Launch39.jpg';
import LaunchThumbnail40 from '../../assets/Launch/LaunchThumbnailimage/Launch40.jpg';
import LaunchThumbnail41 from '../../assets/Launch/LaunchThumbnailimage/Launch41.jpg';
import LaunchThumbnail42 from '../../assets/Launch/LaunchThumbnailimage/Launch42.jpg';
import LaunchThumbnail43 from '../../assets/Launch/LaunchThumbnailimage/Launch43.jpg';
import LaunchThumbnail44 from '../../assets/Launch/LaunchThumbnailimage/Launch44.jpg';
import LaunchThumbnail45 from '../../assets/Launch/LaunchThumbnailimage/Launch45.jpg';
import LaunchThumbnail46 from '../../assets/Launch/LaunchThumbnailimage/Launch46.jpg';
import LaunchThumbnail47 from '../../assets/Launch/LaunchThumbnailimage/Launch47.jpg';
import LaunchThumbnail48 from '../../assets/Launch/LaunchThumbnailimage/Launch48.jpg';
import LaunchThumbnail49 from '../../assets/Launch/LaunchThumbnailimage/Launch49.jpg';
import LaunchThumbnail50 from '../../assets/Launch/LaunchThumbnailimage/Launch50.jpg';
import LaunchThumbnail51 from '../../assets/Launch/LaunchThumbnailimage/Launch51.jpg';
import LaunchThumbnail52 from '../../assets/Launch/LaunchThumbnailimage/Launch52.jpg';
import LaunchThumbnail53 from '../../assets/Launch/LaunchThumbnailimage/Launch53.jpg';
import LaunchThumbnail54 from '../../assets/Launch/LaunchThumbnailimage/Launch54.jpg';
import LaunchThumbnail55 from '../../assets/Launch/LaunchThumbnailimage/Launch55.jpg';
import LaunchThumbnail56 from '../../assets/Launch/LaunchThumbnailimage/Launch56.jpg';
import LaunchThumbnail57 from '../../assets/Launch/LaunchThumbnailimage/Launch57.jpg';
import LaunchThumbnail58 from '../../assets/Launch/LaunchThumbnailimage/Launch58.jpg';
import LaunchThumbnail59 from '../../assets/Launch/LaunchThumbnailimage/Launch59.jpg';
import LaunchThumbnail60 from '../../assets/Launch/LaunchThumbnailimage/Launch60.jpg';
import LaunchThumbnail61 from '../../assets/Launch/LaunchThumbnailimage/Launch61.jpg';
import LaunchThumbnail62 from '../../assets/Launch/LaunchThumbnailimage/Launch62.jpg';
import LaunchThumbnail63 from '../../assets/Launch/LaunchThumbnailimage/Launch63.jpg';
import LaunchThumbnail64 from '../../assets/Launch/LaunchThumbnailimage/Launch64.jpg';
import LaunchThumbnail65 from '../../assets/Launch/LaunchThumbnailimage/Launch65.jpg';
import LaunchThumbnail66 from '../../assets/Launch/LaunchThumbnailimage/Launch66.jpg';
import LaunchThumbnail67 from '../../assets/Launch/LaunchThumbnailimage/Launch67.jpg';
import LaunchThumbnail68 from '../../assets/Launch/LaunchThumbnailimage/Launch68.jpg';
import LaunchThumbnail69 from '../../assets/Launch/LaunchThumbnailimage/Launch69.jpg';
import LaunchThumbnail70 from '../../assets/Launch/LaunchThumbnailimage/Launch70.jpg';
import LaunchThumbnail71 from '../../assets/Launch/LaunchThumbnailimage/Launch71.jpg';
import LaunchThumbnail72 from '../../assets/Launch/LaunchThumbnailimage/Launch72.jpg';
import LaunchThumbnail73 from '../../assets/Launch/LaunchThumbnailimage/Launch73.jpg';
import LaunchThumbnail74 from '../../assets/Launch/LaunchThumbnailimage/Launch74.jpg';
import LaunchThumbnail75 from '../../assets/Launch/LaunchThumbnailimage/Launch75.jpg';
import LaunchThumbnail76 from '../../assets/Launch/LaunchThumbnailimage/Launch76.jpg';
import LaunchThumbnail77 from '../../assets/Launch/LaunchThumbnailimage/Launch77.jpg';
import LaunchThumbnail78 from '../../assets/Launch/LaunchThumbnailimage/Launch78.jpg';

// const Launch = [
//     {
//         id:1,
//         img: Launch1
//     },
//     {
//         id:2,
//         img: Launch2
//     },
//     {
//         id:3,
//         img: Launch3
//     },
//     {
//         id:4,
//         img: Launch4
//     },
//     {
//         id:5,
//         img: Launch5
//     },
//     {
//         id:6,
//         img: Launch6
//     },
//     {
//         id:7,
//         img: Launch7
//     },
//     {
//         id:8,
//         img: Launch8
//     },
//     {
//         id:9,
//         img: Launch9
//     },
//     {
//         id:10,
//         img: Launch10
//     },
//     {
//         id:11,
//         img: Launch11
//     },
//     {
//         id:12,
//         img: Launch12
//     },
//     {
//         id:13,
//         img: Launch13
//     },
//     {
//         id:14,
//         img: Launch14
//     },
//     {
//         id:15,
//         img: Launch15
//     },
//     {
//         id:16,
//         img: Launch16
//     },
//     {
//         id:17,
//         img: Launch17
//     },
//     {
//         id:18,
//         img: Launch18
//     },
//     {
//         id:19,
//         img: Launch19
//     },
//     {
//         id:20,
//         img: Launch20
//     },
//     {
//         id:21,
//         img: Launch21
//     },
//     {
//         id:22,
//         img: Launch22
//     },
//     {
//         id:23,
//         img: Launch23
//     },
//     {
//         id:24,
//         img: Launch24
//     },
//     {
//         id:25,
//         img: Launch25
//     },
//     {
//         id:26,
//         img: Launch26
//     },
//     {
//         id:27,
//         img: Launch27
//     },
//     {
//         id:28,
//         img: Launch28
//     },
//     {
//         id:29,
//         img: Launch29
//     }

// ]

const Launch = [
    {
        id: 1,
        img: Launch1,
        imgTb: LaunchThumbnail1,
        imgEvents:HappyMovementsLaunch
    },
    {
        id: 2,
        img: Launch2,
        imgTb: LaunchThumbnail2
    },
    {
        id: 3,
        img: Launch3,
        imgTb: LaunchThumbnail3
    },
    {
        id: 4,
        img: Launch4,
        imgTb: LaunchThumbnail4
    },
    {
        id: 5,
        img: Launch5,
        imgTb: LaunchThumbnail5
    },
    {
        id: 6,
        img: Launch6,
        imgTb: LaunchThumbnail6
    },
    {
        id: 7,
        img: Launch7,
        imgTb: LaunchThumbnail7
    },
    {
        id: 8,
        img: Launch8,
        imgTb: LaunchThumbnail8
    },
    {
        id: 9,
        img: Launch9,
        imgTb: LaunchThumbnail9
    },
    {
        id: 10,
        img: Launch10,
        imgTb: LaunchThumbnail10
    },
    {
        id: 11,
        img: Launch11,
        imgTb: LaunchThumbnail11
    },
    {
        id: 12,
        img: Launch12,
        imgTb: LaunchThumbnail12
    },
    {
        id: 13,
        img: Launch13,
        imgTb: LaunchThumbnail13
    },
    {
        id: 14,
        img: Launch14,
        imgTb: LaunchThumbnail14
    },
    {
        id: 15,
        img: Launch15,
        imgTb: LaunchThumbnail15
    },
    {
        id: 16,
        img: Launch16,
        imgTb: LaunchThumbnail16
    },
    {
        id: 17,
        img: Launch17,
        imgTb: LaunchThumbnail17
    },
    {
        id: 18,
        img: Launch18,
        imgTb: LaunchThumbnail18
    },
    {
        id: 19,
        img: Launch19,
        imgTb: LaunchThumbnail19
    },
    {
        id: 20,
        img: Launch20,
        imgTb: LaunchThumbnail20
    },
    {
        id: 21,
        img: Launch21,
        imgTb: LaunchThumbnail21
    },
    {
        id: 22,
        img: Launch22,
        imgTb: LaunchThumbnail22
    },
    {
        id: 23,
        img: Launch23,
        imgTb: LaunchThumbnail23
    },
    {
        id: 24,
        img: Launch24,
        imgTb: LaunchThumbnail24
    },
    {
        id: 25,
        img: Launch25,
        imgTb: LaunchThumbnail25
    },
    {
        id: 26,
        img: Launch26,
        imgTb: LaunchThumbnail26
    },
    {
        id: 27,
        img: Launch27,
        imgTb: LaunchThumbnail27
    },
    {
        id: 28,
        img: Launch28,
        imgTb: LaunchThumbnail28
    },
    {
        id: 29,
        img: Launch29,
        imgTb: LaunchThumbnail29
    },
    {
        id: 30,
        img: Launch30,
        imgTb: LaunchThumbnail30
    },
    {
        id: 31,
        img: Launch31,
        imgTb: LaunchThumbnail31
    },
    {
        id: 32,
        img: Launch32,
        imgTb: LaunchThumbnail32
    },
    {
        id: 33,
        img: Launch33,
        imgTb: LaunchThumbnail33
    },
    {
        id: 34,
        img: Launch34,
        imgTb: LaunchThumbnail34
    },
    {
        id: 35,
        img: Launch35,
        imgTb: LaunchThumbnail35
    },
    {
        id: 36,
        img: Launch36,
        imgTb: LaunchThumbnail36
    },
    {
        id: 37,
        img: Launch37,
        imgTb: LaunchThumbnail37
    },
    {
        id: 38,
        img: Launch38,
        imgTb: LaunchThumbnail38
    },
    {
        id: 39,
        img: Launch39,
        imgTb: LaunchThumbnail39
    },
    {
        id: 40,
        img: Launch40,
        imgTb: LaunchThumbnail40
    },
    {
        id: 41,
        img: Launch41,
        imgTb: LaunchThumbnail41
    },
    {
        id: 42,
        img: Launch42,
        imgTb: LaunchThumbnail42
    },
    {
        id: 43,
        img: Launch43,
        imgTb: LaunchThumbnail43
    },
    {
        id: 44,
        img: Launch44,
        imgTb: LaunchThumbnail44
    },
    {
        id: 45,
        img: Launch45,
        imgTb: LaunchThumbnail45
    },
    {
        id: 46,
        img: Launch46,
        imgTb: LaunchThumbnail46
    },
    {
        id: 47,
        img: Launch47,
        imgTb: LaunchThumbnail47
    },
    {
        id: 48,
        img: Launch48,
        imgTb: LaunchThumbnail48
    },
    {
        id: 49,
        img: Launch49,
        imgTb: LaunchThumbnail49
    },
    {
        id: 50,
        img: Launch50,
        imgTb: LaunchThumbnail50
    },
    {
        id: 51,
        img: Launch51,
        imgTb: LaunchThumbnail51
    },
    {
        id: 52,
        img: Launch52,
        imgTb: LaunchThumbnail52
    },
    {
        id: 53,
        img: Launch53,
        imgTb: LaunchThumbnail53
    },
    {
        id: 54,
        img: Launch54,
        imgTb: LaunchThumbnail54
    },
    {
        id: 55,
        img: Launch55,
        imgTb: LaunchThumbnail55
    },
    {
        id: 56,
        img: Launch56,
        imgTb: LaunchThumbnail56
    },
    {
        id: 57,
        img: Launch57,
        imgTb: LaunchThumbnail57
    },
    {
        id: 58,
        img: Launch58,
        imgTb: LaunchThumbnail58
    },
    {
        id: 59,
        img: Launch59,
        imgTb: LaunchThumbnail59
    },
    {
        id: 60,
        img: Launch60,
        imgTb: LaunchThumbnail60
    },
    {
        id: 61,
        img: Launch61,
        imgTb: LaunchThumbnail61
    },
    {
        id: 62,
        img: Launch62,
        imgTb: LaunchThumbnail62
    },
    {
        id: 63,
        img: Launch63,
        imgTb: LaunchThumbnail63
    },
    {
        id: 64,
        img: Launch64,
        imgTb: LaunchThumbnail64
    },
    {
        id: 65,
        img: Launch65,
        imgTb: LaunchThumbnail65
    },
    {
        id: 66,
        img: Launch66,
        imgTb: LaunchThumbnail66
    },
    {
        id: 67,
        img: Launch67,
        imgTb: LaunchThumbnail67
    },
    {
        id: 68,
        img: Launch68,
        imgTb: LaunchThumbnail68
    },
    {
        id: 69,
        img: Launch69,
        imgTb: LaunchThumbnail69
    },
    {
        id: 70,
        img: Launch70,
        imgTb: LaunchThumbnail70
    },
    {
        id: 71,
        img: Launch71,
        imgTb: LaunchThumbnail71
    },
    {
        id: 72,
        img: Launch72,
        imgTb: LaunchThumbnail72
    },
    {
        id: 73,
        img: Launch73,
        imgTb: LaunchThumbnail73
    },
    {
        id: 74,
        img: Launch74,
        imgTb: LaunchThumbnail74
    },
    {
        id: 75,
        img: Launch75,
        imgTb: LaunchThumbnail75
    },
    {
        id: 76,
        img: Launch76,
        imgTb: LaunchThumbnail76
    },
    {
        id: 77,
        img: Launch77,
        imgTb: LaunchThumbnail77
    },
    {
        id: 78,
        img: Launch78,
        imgTb: LaunchThumbnail78
    }
];


export default Launch;