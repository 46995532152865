import handOver1 from '../../assets/VHandOver/handOver1.jpeg';
import handOver2 from '../../assets/VHandOver/handOver2.jpeg';
import handOver3 from '../../assets/VHandOver/handOver3.jpeg';
import handOver4 from '../../assets/VHandOver/handOver4.jpeg';
import handOver5 from '../../assets/VHandOver/handOver5.jpeg';
import handOver6 from '../../assets/VHandOver/handOver6.jpeg';
import handOver7 from '../../assets/VHandOver/handOver7.jpeg';
import handOver8 from '../../assets/VHandOver/handOver8.jpeg';
import handOver9 from '../../assets/VHandOver/handOver9.jpeg';
import handOver10 from '../../assets/VHandOver/handOver10.jpg';
import handOver11 from '../../assets/VHandOver/handOver11.jpg';
import handOver12 from '../../assets/VHandOver/handOver12.jpg';
import handOver13 from '../../assets/VHandOver/handOver13.jpg';
import handOver14 from '../../assets/VHandOver/handOver14.jpg';
import handOver15 from '../../assets/VHandOver/handOver15.jpg';
import handOver16 from '../../assets/VHandOver/handOver16.jpg';
import handOver17 from '../../assets/VHandOver/handOver17.jpg';
import handOver18 from '../../assets/VHandOver/handOver18.jpg';
import handOver19 from '../../assets/VHandOver/handOver19.jpg';
import handOver20 from '../../assets/VHandOver/handOver20.jpg';
import handOver21 from '../../assets/VHandOver/handOver21.jpg';
import handOver22 from '../../assets/VHandOver/handOver22.jpg';
import handOver23 from '../../assets/VHandOver/handOver23.jpg';
import handOver24 from '../../assets/VHandOver/handOver24.jpg';
import HappyMovementshandOver6 from '../../assets/VHandOver/HappyMovementshandOver6.jpeg';

import handOverThumbnail1 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver1.jpeg';
import handOverThumbnail2 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver2.jpeg';
import handOverThumbnail3 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver3.jpeg';
import handOverThumbnail4 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver4.jpeg';
import handOverThumbnail5 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver5.jpeg';
import handOverThumbnail6 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver6.jpeg';
import handOverThumbnail7 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver7.jpeg';
import handOverThumbnail8 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver8.jpeg';
import handOverThumbnail9 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver9.jpeg';
import handOverThumbnail10 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver10.jpg';
import handOverThumbnail11 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver11.jpg';
import handOverThumbnail12 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver12.jpg';
import handOverThumbnail13 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver13.jpg';
import handOverThumbnail14 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver14.jpg';
import handOverThumbnail15 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver15.jpg';
import handOverThumbnail16 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver16.jpg';
import handOverThumbnail17 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver17.jpg';
import handOverThumbnail18 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver18.jpg';
import handOverThumbnail19 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver19.jpg';
import handOverThumbnail20 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver20.jpg';
import handOverThumbnail21 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver21.jpg';
import handOverThumbnail22 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver22.jpg';
import handOverThumbnail23 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver23.jpg';
import handOverThumbnail24 from '../../assets/VHandOver/VHandOverThumbnailimage/handOver24.jpg';

// const HandOver = [
//     {
//         id:1,
//         img: handOver1
//     },
//     {
//         id:2,
//         img: handOver2
//     },
//     {
//         id:3,
//         img: handOver3
//     },
//     {
//         id:4,
//         img: handOver4
//     },
//     {
//         id:5,
//         img: handOver5
//     },
//     {
//         id:6,
//         img: handOver6
//     },
//     {
//         id:7,
//         img: handOver7
//     },
//     {
//         id:8,
//         img: handOver8
//     },
//     {
//         id:9,
//         img: handOver9
//     },
//     {
//         id:10,
//         img: handOver10
//     },
//     {
//         id:11,
//         img: handOver11
//     },
//     {
//         id:12,
//         img: handOver12
//     },
//     {
//         id:13,
//         img: handOver13
//     },
//     {
//         id:14,
//         img: handOver14
//     }

// ]
const HandOver = [
    {
        id: 1,
        img: handOver1,
        imgTb: handOverThumbnail1,
        imgEvents:HappyMovementshandOver6
    },
    {
        id: 2,
        img: handOver2,
        imgTb: handOverThumbnail2
    },
    {
        id: 3,
        img: handOver3,
        imgTb: handOverThumbnail3
    },
    {
        id: 4,
        img: handOver4,
        imgTb: handOverThumbnail4
    },
    {
        id: 5,
        img: handOver5,
        imgTb: handOverThumbnail5
    },
    {
        id: 6,
        img: handOver6,
        imgTb: handOverThumbnail6
    },
    {
        id: 7,
        img: handOver7,
        imgTb: handOverThumbnail7
    },
    {
        id: 8,
        img: handOver8,
        imgTb: handOverThumbnail8
    },
    {
        id: 9,
        img: handOver9,
        imgTb: handOverThumbnail9
    },
    {
        id: 10,
        img: handOver10,
        imgTb: handOverThumbnail10
    },
    {
        id: 11,
        img: handOver11,
        imgTb: handOverThumbnail11
    },
    {
        id: 12,
        img: handOver12,
        imgTb: handOverThumbnail12
    },
    {
        id: 13,
        img: handOver13,
        imgTb: handOverThumbnail13
    },
    {
        id: 14,
        img: handOver14,
        imgTb: handOverThumbnail14
    },
    {
        id: 15,
        img: handOver15,
        imgTb: handOverThumbnail15
    },
    {
        id: 16,
        img: handOver16,
        imgTb: handOverThumbnail16
    },
    {
        id: 17,
        img: handOver17,
        imgTb: handOverThumbnail17
    },
    {
        id: 18,
        img: handOver18,
        imgTb: handOverThumbnail18
    },
    {
        id: 19,
        img: handOver19,
        imgTb: handOverThumbnail19
    },
    {
        id: 20,
        img: handOver20,
        imgTb: handOverThumbnail20
    },
    {
        id: 21,
        img: handOver21,
        imgTb: handOverThumbnail21
    },
    {
        id: 22,
        img: handOver22,
        imgTb: handOverThumbnail22
    },
    {
        id: 23,
        img: handOver23,
        imgTb: handOverThumbnail23
    },
    {
        id: 24,
        img: handOver24,
        imgTb: handOverThumbnail24
    }
];


export default HandOver;