import React, { useRef } from "react";
import { Fade, Slide } from 'react-awesome-reveal';

const LazyComponent = ({ src }) => {
  const videoRef = useRef(null);

  // Function to enable fullscreen
  const handleFullscreen = () => {
    const videoElement = videoRef.current;
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen(); // Standard method
    } else if (videoElement.webkitRequestFullscreen) {
      videoElement.webkitRequestFullscreen(); // Safari
    } else if (videoElement.msRequestFullscreen) {
      videoElement.msRequestFullscreen(); // IE/Edge
    }
  };

  return (
    <div className="relative">
      <div className="text-black text-center indent-3 tracking-widest font-extrabold absolute xl:left-[32%] md:left-[23%] left-[17%] md:top-20 top-10">
      <Fade delay={300} triggerOnce duration={100} cascade className='text-2xl md:text-5xl lg:text-7xl text-white'>
      Keeping up with
      </Fade><br></br>
      <Fade delay={1000} triggerOnce duration={400} cascade className='text-2xl ms-2  md:text-5xl lg:text-7xl font-bold text-[#E20102] '>
       Shesha
      </Fade>
      </div>
      <video
        ref={videoRef}
        src={src}
        autoPlay
        loop
        controls
        className="w-full h-auto cursor-pointer"
        onClick={handleFullscreen}
        />
    </div>
  );
};

export default LazyComponent;